@use '@angular/material' as mat;

.ezp-tabs {
  .mat-tab-label {
    font-size: 14px;
    letter-spacing: 0.003em;
    text-transform: uppercase;

    &-active {
      color: mat.get-color-from-palette(map-get($ezp-theme, primary));
      opacity: 1;
    }

    .mat-icon {
      margin: 0 8px 0 0;
    }
  }
}
