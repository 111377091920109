.form {
  &-label {
    color: #949494;
    display: block;
    font-size: 1.1rem;
    padding: 0 0 1.5rem;
  }

  &-required-fields {
    color: #949494;
    display: flex;
    justify-content: flex-start;
    padding: 5rem 0 0;

    &-label {
      display: block;
      font-size: 1.2rem;
      padding: 0 0 0 0.2rem;
    }

    &-symbol {
      display: block;
      font-size: 1.2rem;
      padding: 0 0.3rem;
    }
  }
}
