@import 'helpers';

.detail-view {
  background: $aside-right-background-color;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  &__header {
    align-items: center;
    border-bottom: 1px solid $primary-border-color;
    display: flex;
    justify-content: space-between;
    padding: 10px $aside-right-horizontal-padding;
    width: 100%;

    &-icon {
      cursor: pointer;
      height: 17px;
      margin-left: auto;
      width: 4px;
    }

    input {
      border: none;
      font-size: 2.2rem;
    }
  }

  &__main {
    height: 100%;
    position: relative;
    width: 100%;
  }

  &__placeholder {
    color: #d6d6d6;
    cursor: default;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    padding: 2rem;
    text-align: center;
    user-select: none;
    @include center-element(true, true);
  }

  &__throbber {
    @include center-element(true, true);
  }
}
