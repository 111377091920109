@import './variables';

.efa-select {
  font-size: 14px;
  width: 100%;

  .mat-form-field {
    &-underline {
      background: $primary-border-color;
    }
  }

  &__option {
    &--primary {
      color: $primary-color !important;
    }
  }
}
