.ezp-platform {
  display: grid;
  grid-gap: 16px;
  grid-template: auto / 1fr;

  &__item {
    align-content: start;
    background: map-get(map-get($ezp-theme, background), background);
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px map-get(map-get($ezp-theme, foreground), divider);
    display: grid;
    grid-gap: 16px;
    grid-template: auto 1fr auto / 1fr;
    padding: 24px 16px 16px;
    position: relative;

    &--connected {
      &::before,
      &::after {
        @media (min-width: map-get($ezp-breakpoints, 'large')) {
          background: url('/assets/images/platform-connection.png');
          background-size: 152px 8px;
          content: '';
          height: 8px;
          position: absolute;
          top: 68px;
          width: 152px;
          z-index: 1;
        }
      }
    }

    &--connected-left {
      &::before {
        @media (min-width: map-get($ezp-breakpoints, 'large')) {
          left: -84px;
        }
      }
    }

    &--connected-right {
      &::after {
        @media (min-width: map-get($ezp-breakpoints, 'large')) {
          right: -84px;
        }
      }
    }
  }

  &__icon {
    height: 96px;
    margin: 0 auto;
    position: relative;

    > img {
      position: absolute;
    }

    &--square {
      width: 96px;

      > img {
        height: 132px;
        left: -18px;
        top: -3px;
        width: 132px;
      }
    }

    &--wide {
      width: 152px;

      > img {
        height: 132px;
        left: -18px;
        top: -3px;
        width: 188px;
      }
    }
  }

  &__details {
  }

  &__title {
    font-weight: 500;
    margin: 0 0 4px;
    text-align: center;
  }

  &__description {
    color: map-get(map-get($ezp-theme, foreground), secondary-text);
    text-align: center;
  }

  &__actions {
    > .ezp-button {
      width: 100%;
    }
  }

  @media (min-width: map-get($ezp-breakpoints, 'medium')) {
    grid-template: auto / repeat(2, 1fr);
  }

  @media (min-width: map-get($ezp-breakpoints, 'large')) {
    grid-template: auto / repeat(3, 1fr);
  }
}
