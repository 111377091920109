@use '@angular/material' as mat;

.ezp-print-now {
  bottom: 16px;
  left: 16px;
  position: fixed;
  z-index: 1;

  &__backdrop {
    background: var(--ezp-core-backdrop);
    display: var(--ezp-print-now-backdrop-display, none);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
  }

  &--area-open {
    --ezp-print-now-toggle-display: none;
    --ezp-print-now-backdrop-display: block;
  }

  &__toggle {
    border-radius: 20px !important;
    display: var(--ezp-print-now-toggle-display, block) !important;
    padding: 0 20px 0 12px !important;
    width: auto !important;
    z-index: 1;
  }
}

// New separate styling for the print now area
.ezp-print-now-area {
  --ezp-upload-height: 100%;
  --ezp-upload-width: 100%;
  --ezp-printerSelection-position: absolute;
  --ezp-printerSelection-height: 100%;

  @media (min-width: 512px) {
    --ezp-printerSelection-width: 480px;
  }

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--ezp-print-now-area-width, 90vw);
  height: var(--ezp-print-now-area-height, 90vh);
  background: white;
  border-radius: 4px;
  box-shadow: var(--ezp-elevation-16);
  box-sizing: border-box;
  display: none;
  grid-template: auto 1fr / 1fr;
  padding: 0 0 16px;
  z-index: 10;

  @media (min-width: 768px) {
    --ezp-print-now-area-width: 80vw;
    --ezp-print-now-area-height: 80vh;
  }

  @media (min-width: 1200px) {
    --ezp-print-now-area-width: 70vw;
    --ezp-print-now-area-height: 70vh;
  }

  &--open {
    display: grid;
  }

  &__header {
    align-items: center;
    display: grid;
    grid-template: auto / 1fr auto;
    height: 48px;
    justify-self: stretch;
    padding: 0 4px 0 16px;
  }

  &__title {
    font-weight: 600;
  }

  &__close {
    color: mat.get-color-from-palette(map-get($ezp-theme, primary));
  }

  &__printing {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    justify-self: stretch;
    padding: 0 16px;
  }
}