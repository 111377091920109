@use '@angular/material' as mat;

.ezp-table {
  $foreground: map-get($ezp-theme, foreground);

  border: 1px solid map-get($foreground, divider);
  border-radius: 4px;

  &__data {
    table-layout: fixed;
    width: 100%;
  }

  &__cell {
    &--icon {
      width: 56px;

      &--double {
        width: 96px;
      }
    }

    &--select {
      width: 52px;
    }

    &--toggle {
      width: 64px;
    }

    &--text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__actions {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin: 0 -8px 0 0;
    }
  }

  &__spinner {
    &,
    > svg {
      height: 24px !important;
      width: 24px !important;
    }
  }

  &__processing {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-table {
    background: transparent;
  }

  .mat-header-cell {
    color: map-get($foreground, text);
    font-size: mat.font-size($ezp-typography, body-2);
  }

  .mat-cell,
  .mat-header-cell {
    &.ezp-table__cell--text {
      padding-left: 12px;
      padding-right: 12px;
    }

    &.ezp-table__cell--soft {
      color: map-get($foreground, secondary-text);
    }
  }

  .mat-row:last-child {
    .mat-cell {
      border-bottom: 0;
    }
  }

  .mat-row {
    &.ezp-table__row {
      &--loading {
        background: var(--ezp-core-shade-primary);

        .mat-cell {
          color: var(--ezp-core-foreground-tertiary);
        }
      }
    }
  }
}
