.ezp-checkbox {
  .mat-checkbox-frame {
    border-color: var(--ezp-checkbox-border-color, var(--ezp-core-foreground-secondary));
  }

  &.mat-checkbox-checked,
  &.mat-checkbox-indeterminate {
    --ezp-checkbox-background-background-color: var(--ezp-brand-primary-solid);
  }

  .mat-checkbox-background {
    background-color: var(--ezp-checkbox-background-background-color, transparent) !important;
  }

  &--strong {
    font-weight: 500;
  }
}
