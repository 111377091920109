@use '@angular/material' as mat;
.ezp-snackbar {
  &__content {
    align-items: start;
    display: grid;
    grid-template: auto / 1fr auto;
  }

  &__message {
    color: hsla(0, 0%, 100%, 0.87);
    font-size: mat.font-size($ezp-typography, body-1);
    font-weight: mat.font-weight($ezp-typography, body-1);
    line-height: 20px;
  }

  &__dismiss {
    &.mat-icon-button {
      margin: -10px -12px 0 16px;
    }
  }
}

.mat-snack-bar-container {
  background: hsl(0, 0%, 30%);
}
