$ezp-breakpoints: (
  'small': 480px,
  'medium': 768px,
  'large': 1224px,
);
$ezp-toolbar-width: 256px;
$ezp-color-teal: #00947b;
$ezp-color-yellow: #eb8100;
$ezp-color-grape: #9d34b2;
$ezp-color-red: #d83f31;
