@use '@angular/material' as mat;
* {
  font-feature-settings: 'calt', 'liga', 'case', 'ss03', 'zero', 'cv05';
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
}

strong {
  font-weight: 600;
}

p,
li {
  a {
    color: mat.get-color-from-palette(map-get($ezp-theme, primary));
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
      text-decoration: none;
    }
  }
}
