// Font
$primary-font: 'Roboto', sans-serif;
$primary-font-color: #2e3538;
$secondary-font-color: transparentize($primary-font-color, 0.4);

// Colors
$primary-border-color: transparentize($primary-font-color, 0.85);
// $primary-color: #1583a2;
$primary-color: #0f97bd; // FIXME for Dash
$primary-color-hover: #1aa8cf;
$primary-color-active: #0b8fb3;
$secondary-color: #be4862;
$secondary-color-hover: #ce7589;
$secondary-color-active: #0bb35e;
$tertiary-color: #607d8b;
$tertiary-color-hover: #78909c;
$tertiary-color-active: #546c78;
$error-color: #c5556d;
$error-color-hover: #c97386;
$error-color-active: #b33a54;

$green-color: #43a047;
$red-color: #bb3c3c;

// Icons
$icon-color-primary: $primary-color;
$icon-color-high-emphasis: $primary-font-color;
$icon-color-medium-emphasis: transparentize($primary-font-color, 0.45);
$icon-color-disabled: transparentize($primary-font-color, 0.7);

// Forms, Buttons, Inputs
$input-border-color: #90a4ae;

$button-border-color: $input-border-color;
$button-border-radius: 11px;
$button-height: 36px;
$button-font-size: 1.2rem;
$button-track: 0.05em;

$text-input-border-radius: $button-border-radius;
$text-input-height: $button-height;

// Context specific
$aside-right-background-color: #f6f8f8;
$aside-right-horizontal-padding: 29px;
$aside-right-vertical-padding: 30px;
$aside-right-header-font-size: 1.5rem;
$aside-right-header-font-weight: 500;

$main-horizontal-padding: 24px;
$main-vertical-padding: 24px;
$main-navigation-background-color: #fff;
$main-navigation-border-color: $primary-color;

// Lists
$list-item-font-color: #979797;
$list-item-border-color: $primary-border-color;
$list-item-hover: #f5f7f8;
$list-item-selected: #eaf5f8;

// Overlay
$overlay-background: rgba(144, 164, 174, 0.65);
$overlay-content-max-width: 700px;
$overlay-content-min-width: 460px;
$overlay-content-width: 50%;
$overlay-content-header-background-color: $aside-right-background-color;

// Animation
$default-transition: 0.4s all ease;

// Breakpoints
$breakpoint-medium-max-width: 1024px;
$breakpoint-small-max-width: 800px;
