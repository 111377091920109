.ezp-splash {
  .mat-dialog-container {
    background-image: linear-gradient(
      140deg,
      var(--ezp-accent-violet-solid),
      var(--ezp-accent-cyan-solid) 80%
    );
    background-position: center;
    background-size: 120%;
    border-radius: 12px;
    box-shadow: inset 0 0 0 1px var(--ezp-black-10), 0 10px 16px 0 var(--ezp-black-10),
      0 26px 42px 0 var(--ezp-black-10);
    color: var(--ezp-white-100);
    padding: 24px 24px 0 24px;
  }

  &__actions {
    &.mat-dialog-actions {
      --ezp-checkbox-border-color: var(--ezp-white-50);
      --ezp-button-custom-min-width: 128px;

      align-items: var(--ezp-splash-actions-align-items, center);
      flex-direction: var(--ezp-splash-actions-flex-direction, row);
      gap: 8px;
      justify-content: var(--ezp-splash-actions-justify-content, flex-start);
      margin: 0;
      min-height: auto;
      padding: 16px 0 24px 0;
    }

    &--stacked {
      --ezp-splash-actions-flex-direction: column;
      --ezp-splash-actions-align-items: stretch;
    }

    &--spread {
      --ezp-splash-actions-justify-content: space-between;
    }
  }
}
