.ezp-dialog {
  &__controls {
    position: absolute;
    right: 16px;
    top: 16px;

    &__action {
      color: map-get(map-get($ezp-theme, foreground), secondary-text);
    }
  }

  .mat-dialog-container {
    position: relative;
  }

  .mat-tab-header {
    background: map-get(map-get($ezp-theme, background), dialog);
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .mat-tab-body-wrapper {
    z-index: 0;
  }

  .mat-dialog-content {
    &.ezp-dialog__content {
      &--wrapped {
        margin-bottom: -24px;
      }
    }
  }

  .mat-dialog-actions {
    justify-content: flex-end;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 16px;

    &.ezp-dialog__actions {
      &--spread {
        justify-content: space-between;

        .mat-checkbox {
          margin: 0 0 0 16px;
        }
      }

      &--wrapped {
        margin-bottom: 0;
      }
    }
    &__action_left {
      margin-right: auto;
    }
  }
}
