@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400 600;
  src: url('../../../../assets/fonts/Inter-roman.var.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400 600;
  src: url('../../../../assets/fonts/Inter-italic.var.woff2') format('woff2');
}
