@import './helpers';
@import './variables';

.aside {
  &-right {
    $header-height: 115px;
    background: $aside-right-background-color;
    bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &__header {
      display: flex;
      flex-direction: column;
      height: $header-height;
      justify-content: space-between;
      position: absolute;
      width: 100%;

      &-title {
        align-items: center;
        border-bottom: 1px solid $primary-border-color;
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding: 0 $aside-right-horizontal-padding;
        width: 100%;

        &__label {
          font-size: 1.5rem;
          padding: 0;
        }

        &__button {
          cursor: pointer;
          height: 17px;
          margin-left: auto;
          padding: 0 0 0 16px;
          width: 20px;
          g {
            fill: $primary-color;
            //fill: orangered;
          }
        }
      }
    }

    &__main {
      bottom: 0;
      // padding: $aside-right-vertical-padding $aside-right-horizontal-padding;
      position: absolute;
      top: $header-height;
      width: 100%;
    }

    &__navigation {
      background: #fff;
      display: flex;
      height: 100%;
      overflow-y: auto;
      width: 100%;

      &-item {
        align-items: center;
        background: #fff;
        border-bottom: 1px solid $primary-border-color;
        border-left: 1px solid $primary-border-color;
        border-top: 3px solid transparent;
        color: $primary-color;
        cursor: pointer;
        display: flex;
        font-size: 1.1rem;
        justify-content: center;
        //height: 56px;
        line-height: 1.3;
        padding: 1rem 1rem;
        text-align: center;
        transition: 0.4s all ease;
        user-select: none;
        width: 100%;

        &--active {
          background: $aside-right-background-color;
          border-bottom: 1px solid transparent;
          border-top: 3px solid $primary-color;
          color: $primary-font-color;
          cursor: default;
          font-weight: 600;
        }

        &--disabled {
          background: #fff;
          cursor: default;
          opacity: 0.2;
          pointer-events: none;
        }

        &:first-child {
          border-left: none;
        }
      }
    }

    &__throbber {
      background: $aside-right-background-color;
      bottom: 0;
      position: absolute;
      top: 0;
      width: 100%;

      &-inner {
        @include center-element(true, true);
      }
    }
  }
}
