@import './variables';

.ezd {
  .mat-radio-button {
    &.efa-radio__button {
      color: $primary-font-color;

      // @include mat-typography-level-to-styles($custom-typography, body-2);

      margin: 8px 0 0;

      &:first-child {
        margin-top: 0;
      }

      &--list {
        margin: 0;
      }
    }
  }

  .efa-radio {
    &__group {
      margin: 0;

      &--block {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
