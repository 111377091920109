@use '@angular/material' as mat;
.ezp-guides {
  display: grid;
  grid-gap: 24px;
  grid-template: auto / 1fr;
  overflow: hidden;

  &__guide {
    display: grid;
    grid-gap: 16px;
    grid-template: repeat(2, auto) / 1fr;

    &.teal {
      --color-accent: #{$ezp-color-teal};
    }

    &.yellow {
      --color-accent: #{$ezp-color-yellow};
    }

    &.grape {
      --color-accent: #{$ezp-color-grape};
    }

    &__title {
      margin: 0;
    }

    &__items {
      display: grid;
      grid-gap: 16px;
      grid-template: auto / 1fr;

      @media (min-width: map-get($ezp-breakpoints, 'medium')) {
        grid-template: auto / repeat(2, 1fr);
      }

      @media (min-width: map-get($ezp-breakpoints, 'large')) {
        grid-template: auto / repeat(3, 1fr);
      }
    }

    &__item {
      background: map-get(map-get($ezp-theme, background), background);
      border-radius: 4px;
      box-shadow: inset 0 0 0 1px map-get(map-get($ezp-theme, foreground), divider);
      box-sizing: border-box;
      display: grid;
      flex: 1 1 0;
      grid-gap: 8px;
      grid-template: auto 1fr / 1fr;
      padding: 16px 16px 16px 24px;
      position: relative;

      &::before {
        background: var(--color-accent);
        border-radius: 4px 0 0 4px;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 8px;
      }

      &__head {
        &.indexed {
          align-items: start;
          display: grid;
          grid-gap: 16px;
          grid-template: auto / 24px 1fr;
        }
      }

      &__index {
        background: var(--color-accent);
        border-radius: 12px;
        color: white;
        font-size: mat.font-size($ezp-typography, body-1);
        font-weight: 600;
        font-weight: 600;
        line-height: mat.line-height($ezp-typography, subheading-2);
        text-align: center;
      }

      &__title {
        font-weight: 500;
        margin: 0;
      }

      &__content {
        color: map-get(map-get($ezp-theme, foreground), primary-text);

        &.indexed {
          padding: 0 0 0 40px;
        }

        p,
        ul {
          margin: 0;

          a {
            color: var(--color-accent);
          }
        }

        ul {
          list-style: none;
          padding: 0;

          li {
            padding: 0 0 0 16px;
            position: relative;

            &::before {
              color: var(--color-accent);
              content: '\002022';
              left: 0;
              position: absolute;
            }

            & + li {
              margin: 4px 0 0;
            }
          }
        }
      }
    }
  }
}
