.ezp-scenario {
  background: map-get(map-get($ezp-theme, background), background);
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px map-get(map-get($ezp-theme, foreground), divider);
  margin: 24px 0;
  padding: 24px;

  &__image {
    display: block;
    max-width: 100%;
  }
}
