@use '@angular/material' as mat;
.ezp-icon {
  $foreground: map-get($ezp-theme, foreground);

  &--high-emphasis {
    color: map-get($foreground, text);
  }

  &--medium-emphasis {
    color: map-get($foreground, secondary-text);
  }

  &--disabled {
    color: map-get($foreground, disabled-text);
  }

  &--primary {
    color: mat.get-color-from-palette(map-get($ezp-theme, primary));
  }

  &--accent {
    color: mat.get-color-from-palette(map-get($ezp-theme, accent));
  }
}
