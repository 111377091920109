.ezp-animation {
  display: block;
  height: var(--ezp-animation-height);
  position: relative;
  width: var(--ezp-animation-width);

  &--welcome-dialog {
    --ezp-animation-width: 224px;
    --ezp-animation-height: 224px;
  }
}
