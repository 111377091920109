@import './variables';

.efa-tabs {
  &--main-nav {
    margin: ($main-vertical-padding * 0.5) 0 0;
  }

  &__icon {
    margin: 0 8px 0 0;
  }

  .mat-tab-label-content {
    letter-spacing: $button-track;
    text-transform: uppercase;
  }
}
