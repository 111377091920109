// ==========================================================================
// Modules
// ==========================================================================

@use '@cortado-holding/colors' with (
  $namespace: 'ezp',
  $useDarkMode: false,
  $useDisplayP3: false
);
@use '@cortado-holding/colors/accents';
@use '@cortado-holding/colors/brands' with (
  $brand: 'ezeep'
);
@use '@cortado-holding/colors/core' with (
  $useDisplayP3: false
);
@use '@cortado-holding/colors/black';
@use '@cortado-holding/colors/white';
@use '@cortado-holding/colors/gray';

// ==========================================================================
// Imports
// ==========================================================================

// Redesign
@import './app/styles/_redesign/core/variables';
@import './app/styles/_redesign/core/material';
@import './app/styles/_redesign/core/fonts';
@import './app/styles/_redesign/core/icons';
@import './app/styles/_redesign/core/base';
@import './app/styles/_redesign/core/utils';
@import './app/styles/_redesign/components/animation';
@import './app/styles/_redesign/components/bullets';
@import './app/styles/_redesign/components/button';
@import './app/styles/_redesign/components/checkbox';
@import './app/styles/_redesign/components/code';
@import './app/styles/_redesign/components/dialog';
@import './app/styles/_redesign/components/download';
@import './app/styles/_redesign/components/drawer';
@import './app/styles/_redesign/components/form-field';
@import './app/styles/_redesign/components/grid';
@import './app/styles/_redesign/components/guides';
@import './app/styles/_redesign/components/icon';
@import './app/styles/_redesign/components/layout';
@import './app/styles/_redesign/components/list';
@import './app/styles/_redesign/components/menu';
@import './app/styles/_redesign/components/note';
@import './app/styles/_redesign/components/panels';
@import './app/styles/_redesign/components/paragraph';
@import './app/styles/_redesign/components/platform';
@import './app/styles/_redesign/components/print-now';
@import './app/styles/_redesign/components/scenario';
@import './app/styles/_redesign/components/sequence';
@import './app/styles/_redesign/components/snackbar';
@import './app/styles/_redesign/components/spinner';
@import './app/styles/_redesign/components/splash';
@import './app/styles/_redesign/components/table';
@import './app/styles/_redesign/components/tabs';
@import './app/styles/_redesign/components/text';
@import './app/styles/_redesign/components/toolbar';
@import '@angular/cdk/overlay-prebuilt.css';

// Legacy styles

@import './app/styles/variables';
@import './app/styles/aside';
@import './app/styles/detail-view';
@import './app/styles/button';
@import './app/styles/content';
@import './app/styles/form';
@import './app/styles/layout';
@import './app/styles/list';
@import './app/styles/helpers';
@import './app/styles/overlay';

// Vendor
// @import './app/modules/material/material';

// efa
@import './app/styles/checkbox';
@import './app/styles/icon';
@import './app/styles/image';
@import './app/styles/label';
@import './app/styles/note';
@import './app/styles/paginator';
@import './app/styles/radio';
@import './app/styles/select';
@import './app/styles/table';
@import './app/styles/tabs';
@import './app/styles/text';
@import './app/styles/text-field';

// ==========================================================================
// Globals
// ==========================================================================

* {
  margin: 0;

  &:focus {
    outline: none;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.ezp-guide {
  &__animation-player {
    display: block;
    flex-shrink: 0;
    height: 400px;
  }
}

/* Update the Inter font import */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* Or if you prefer the variable font version */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap&subset=latin&display=swap');
