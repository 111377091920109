@use '@angular/material' as mat;

.ezp-panels {
  display: grid;
  grid-gap: 24px;
  grid-template: 1fr / 1fr;

  &__item {
    align-content: start;
    background: map-get(map-get($ezp-theme, background), background);
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px map-get(map-get($ezp-theme, foreground), divider);
    display: grid;
    grid-gap: 16px;
    grid-template: repeat(4, auto) / 1fr;
    padding: 24px;

    &__title {
      font-weight: 600;
      margin: 0 !important;
    }

    &__description {
      margin: 0 !important;
    }

    &__actions {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: space-between;

      mat-slide-toggle {
        margin-bottom: 3px;
      }
    }

    @media (min-width: map-get($ezp-breakpoints, 'medium')) {
      height: 100%;
      max-height: 480px;
      overflow: hidden;
    }
  }

  &__list {
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 0 1px map-get(map-get($ezp-theme, foreground), divider);
    max-height: 240px;
    overflow: auto;

    &--disabled {
      opacity: 0.42;
      pointer-events: none;
    }

    &__item {
      align-items: center;
      display: grid;
      grid-gap: 8px;

      &:not(:last-child) {
        box-shadow: inset 0 -1px 0 0 map-get(map-get($ezp-theme, foreground), divider);
      }

      &--action-leading {
        grid-template: 48px / auto 1fr;
        padding: 0 16px 0 8px;
      }

      &--action-trailing {
        grid-template: 48px / 1fr auto;
        padding: 0 8px 0 16px;
      }

      &__title {
      }

      &__link {
        align-items: center;
        border-radius: 50%;
        color: mat.get-color-from-palette(map-get($ezp-theme, primary));
        display: flex;
        height: 36px;
        justify-content: center;
        text-decoration: none;
        width: 36px;
      }
    }

    @media (min-width: map-get($ezp-breakpoints, 'medium')) {
      max-height: none;
    }
  }

  @media (min-width: map-get($ezp-breakpoints, 'medium')) {
    height: 100%;
  }

  @media (min-width: map-get($ezp-breakpoints, 'large')) {
    grid-template: auto / repeat(2, 1fr);
  }
}
