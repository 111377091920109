.ezp-sequence {
  counter-reset: sequence-counter;
  list-style: none;
  padding: 0;

  &__item {
    counter-increment: sequence-counter;
    padding: 0 0 0 24px;
    position: relative;

    &::before {
      content: counter(sequence-counter) '. ';
      font-weight: 500;
      left: 0;
      position: absolute;
      top: 0;
    }
  }
}
