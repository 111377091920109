.ezp-layout {
  &__page {
    &--toolbar {
      padding: 0 $ezp-toolbar-width 0 0;
    }

    &--contain {
      @media (min-width: map-get($ezp-breakpoints, 'medium')) {
        height: 100%;
      }
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 28px 24px 32px;

    &--contain {
      @media (min-width: map-get($ezp-breakpoints, 'medium')) {
        display: grid;
        grid-gap: 8px 0;
        grid-template: auto minmax(400px, 1fr) / 1fr;
        height: 100%;
        padding: 28px 32px 32px;
      }
    }

    @media (min-width: map-get($ezp-breakpoints, 'medium')) {
      padding: 28px 32px 32px;
    }
  }

  &__head {
    align-items: start;
    display: grid;
    grid-gap: 0 16px;
    grid-template: auto / 1fr;

    &--split {
      grid-template: auto / 1fr auto;
    }

    &__actions {
      margin: 2px 0 0;
    }
  }

  &__part {
    margin: 24px 0 0;

    &--contain {
      @media (min-width: map-get($ezp-breakpoints, 'medium')) {
        height: 100%;
        margin: 0;
      }
    }

    &--inner {
      margin: 16px 0 0;
    }

    &--inset {
      &--large {
        padding-left: 64px;
      }
    }
  }
}
