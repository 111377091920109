@import './variables';

%base-overlay {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

%overlay-background {
  background: $overlay-background;
  bottom: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
}

%overlay-content {
  background: #fff;
  bottom: 0;
  height: 100vh;
  max-width: $overlay-content-max-width;
  min-width: $overlay-content-min-width;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: $overlay-content-width;
}

.overlay {
  z-index: 300;

  &__background {
    @extend %overlay-background;
  }

  &-content {
    @extend %overlay-content;
  }
  @extend %base-overlay;
}

.wizardoverlay {
  z-index: 200;

  &__background {
    @extend %overlay-background;
  }

  &-content {
    @extend %overlay-content;
  }
  @extend %base-overlay;
}

.overlay-spinner {
  background-color: rgb(255, 255, 255);
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.overlay-spinner-content {
  left: 50%;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
