@import './variables';

.button {
  align-items: center;
  border-radius: $button-border-radius;
  cursor: pointer;
  display: flex;
  font-size: $button-font-size;
  font-weight: 400;
  height: $button-height;
  justify-content: center;
  line-height: $button-height;
  overflow: hidden;
  padding: 0 2rem;
  text-align: center;
  text-overflow: ellipsis;
  transition: 0.3s all ease;
  user-select: none;
  white-space: nowrap;

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  &--primary {
    background: $primary-color;
    border: 2px solid transparent;
    color: #fff;

    &:active {
      background: $primary-color-hover;
    }

    &:hover {
      background: $primary-color-hover;
    }

    &-outline {
      background: none;
      border: 1px solid $primary-color !important;
      color: $primary-color;

      svg {
        path {
          fill: $primary-color !important;
        }
      }
    }

    svg {
      path {
        fill: $primary-color !important;
      }
    }
  }

  &--secondary {
    background: $secondary-color;
    color: #fff;

    &:hover {
      background: $secondary-color-hover;
    }

    &-outline {
      background: none;
      border: 1px solid $secondary-color !important;
      color: $secondary-color;

      svg {
        path {
          fill: $secondary-color !important;
        }
      }
    }
  }

  &--icon {
    $icon-size: 13px;

    i {
      display: block;
      height: $icon-size;
      margin-right: 8px;
      width: $icon-size;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  &--small {
    font-size: 1.1rem;
    height: 25px;
    line-height: 25px;
    padding: 0 1rem;

    i {
      height: 8px;
      width: 8px;
    }
  }
}

.efa-button {
  border-radius: 4px !important;
  letter-spacing: $button-track;
  text-transform: uppercase;

  .mat-icon {
    margin: 0 4px 0 -4px;
    position: relative;
    top: -1px;
  }
}

.request-driver-button {
  display: flex;
  font-weight: 400 !important;
  vertical-align: middle;
  width: 100%;
}
