@import './helpers';
@import './variables';

.list {
  &-filter {
    align-items: center;
    border: 1px solid $button-border-color;
    border-radius: $button-border-radius;
    display: flex;
    margin-left: auto;
    position: relative;
    transition: 0.4s all ease;
    width: 200px;

    &--highlighted {
      border: 1px solid $primary-color;
      width: 300px;
    }

    &__input {
      border: none;
      margin-left: 30px;
      margin-right: 40px;
      width: 100%;
    }

    &__icon {
      $icon-size: 20px;
      height: $icon-size;
      left: 0;
      margin: 0 0 0 10px;
      position: absolute;
      width: $icon-size;
    }

    &__clear-button {
      $size: 30px;
      background: $error-color;
      border-radius: 50%;
      height: $size;
      margin: 0 5px 0 0;
      padding: 10px;
      position: absolute;
      right: 0;
      text-align: center;
      width: $size;

      svg {
        display: block;

        path {
          stroke: #fff;
        }
      }
    }
  }

  &-placeholder {
    line-height: 1.4;
    padding: 0 0 7rem;
    text-align: center;

    &__text {
      padding: 0 0 1rem;
    }

    &--empty,
    &--error {
      color: #999;
      font-size: 1.5rem;
      font-weight: 300;
    }
    @include center-element(true, true);
  }
}

.efa-list {
  &__item {
    &--dense {
      .mat-list-text {
        font-size: 14px;
      }
    }

    &--primary {
      background-color: rgba($color: #000000, $alpha: 0.04);
      color: $primary-color !important;
      .mat-list-text {
        font-size: 14px;
      }
    }
  }
}
