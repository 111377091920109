@import './variables';

.ezd {
  .efa-text {
    &--he {
      color: $primary-font-color;
    }

    &--me {
      color: darken($secondary-font-color, 10%);
    }

    &--prm {
      color: $primary-color;
    }

    &--no-spacing {
      margin: 0;
    }

    &--paragraph,
    &--list {
      margin: 1em 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--list {
      $_offset: 24px;

      padding: 0 0 0 $_offset !important;

      &--order {
        list-style-type: none;
        padding: 0;

        li {
          counter-increment: order-counter;
          position: relative;

          &::before {
            color: $primary-color;
            content: counter(order-counter) '.';
            font-weight: 500;
            left: $_offset * -1;
            position: absolute;
            top: 0;
          }
        }
      }

      &--bullets {
        li {
          margin: 0.25em 0;
          position: relative;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &::before {
            background: $primary-color;
            border-radius: 50%;
            content: '';
            left: $_offset * -1;
            position: absolute;
          }
        }

        &.mat-body-1 {
          li::before {
            $_bullet-size: 8px;
            height: $_bullet-size;
            top: calc(12px - #{$_bullet-size * 0.5});

            width: $_bullet-size;
          }
        }

        &.mat-body-2 {
          li::before {
            $_bullet-size: 6px;
            height: $_bullet-size;
            top: calc(11px - #{$_bullet-size * 0.5});

            width: $_bullet-size;
          }
        }
      }
    }

    &--status {
      /**

        TODO:
        - generic status class

       */

      position: relative;

      &::before {
        border-radius: 6px;
        content: '';
        height: 12px;
        left: -16px;
        position: absolute;
        top: 5px;
        width: 12px;
      }

      &--ok {
        &::before {
          background: $green-color;
        }
      }

      &--off {
        &::before {
          background: $red-color;
        }
      }
    }

    &--pre {
      border-radius: 4px;
      display: inline-flex;
      font-family: monospace;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 16px;

      &--prm {
        background: transparentize($primary-color, 0.9);
        box-shadow: inset 0 0 0 1px transparentize($primary-color, 0.8);
      }
    }

    &--link {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      margin: 8px 0;

      &:hover {
        opacity: 0.7;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      > .mat-icon {
        margin: 0 8px 0 0;
        text-decoration: none;
      }
    }

    strong {
      font-weight: 500;
    }

    a {
      text-decoration: underline;
    }
  }
}
