.content {
  &__headline {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &--1 {
      border-bottom: 1px solid $primary-border-color;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 36px;
      margin: 0 0 2rem;
      padding: 0 0 2rem;
    }

    &--2 {
      border-bottom: 1px solid $primary-border-color;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1;
      margin: 0 0 2rem;
      padding: 0 0 2rem;
    }
  }
}
