@use '@angular/material' as mat;
.ezp-bullets {
  list-style: none;
  padding: 0;

  &__item {
    padding: 0 0 0 24px;
    position: relative;

    &::before {
      color: mat.get-color-from-palette(map-get($ezp-theme, primary));
      content: '\002022';
      left: 0;
      position: absolute;
    }
  }
}
