.ezp-grid {
  $gutter: 16px;
  $spacing: 24px;

  display: grid;

  &--gutter {
    grid-gap: $gutter;
  }

  &--gutter-horizontal {
    grid-column-gap: $gutter;
  }

  &--gutter-vertical {
    grid-row-gap: $gutter;
  }

  &--spacing {
    padding: $spacing;
  }

  &--spacing-horizontal {
    padding-left: $spacing;
    padding-right: $spacing;
  }

  &--spacing-vertical {
    padding-bottom: $spacing;
    padding-top: $spacing;
  }

  &--spacing-left {
    padding-left: $spacing;
  }

  &--spacing-right {
    padding-right: $spacing;
  }

  &--spacing-top {
    padding-top: $spacing;
  }

  &--spacing-bottom {
    padding-bottom: $spacing;
  }

  &--separate-top {
    border-top: 1px solid map-get(map-get($ezp-theme, foreground), divider);
  }

  &--separate-bottom {
    border-bottom: 1px solid map-get(map-get($ezp-theme, foreground), divider);
  }

  &[data-grid-cols='1'] {
    grid-template: auto / 1fr;
  }

  &[data-grid-cols='2'] {
    grid-template: auto / repeat(2, 1fr);
  }
}
