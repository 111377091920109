@use '@angular/material' as mat;
.ezp-download {
  display: grid;
  grid-gap: 24px;
  grid-template: repeat(2, auto) / 1fr;

  &__head {
    display: grid;
    grid-gap: 8px 24px;
    grid-template: repeat(2, auto) / 96px 1fr;
  }

  &__icon {
    align-self: start;
    display: block;
    grid-area: 1 / 1 / 3 / 2;
    width: 100%;
  }

  &__title {
    align-self: end;
    grid-area: 1 / 2 / 2 / 3;
    margin: 0 !important;
  }

  &__description {
    align-self: start;
    grid-area: 2 / 2 / 3 / 3;
    margin: 0 !important;
  }

  &__collections {
    display: grid;
    grid-gap: 24px;
    grid-template: auto / 1fr;

    @media (min-width: map-get($ezp-breakpoints, 'medium')) {
      grid-template: auto / repeat(2, 1fr);
    }
  }

  &__collection {
    align-content: start;
    background: map-get(map-get($ezp-theme, background), background);
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px map-get(map-get($ezp-theme, foreground), divider);
    display: grid;
    grid-gap: 16px;
    grid-template: repeat(3, auto) / 1fr;
    padding: 24px;

    &__title {
      font-weight: 600;
      margin: 0 !important;
    }

    &__description {
      margin: 0 !important;
    }
  }

  &__items {
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 0 1px map-get(map-get($ezp-theme, foreground), divider);
  }

  &__item {
    align-items: center;
    display: grid;
    grid-template: 48px / 1fr auto;
    padding: 0 8px 0 16px;

    &:not(:last-child) {
      box-shadow: inset 0 -1px 0 0 map-get(map-get($ezp-theme, foreground), divider);
    }

    &__title {
    }

    &__link {
      align-items: center;
      border-radius: 50%;
      color: mat.get-color-from-palette(map-get($ezp-theme, primary));
      display: flex;
      height: 36px;
      justify-content: center;
      text-decoration: none;
      width: 36px;
    }
  }
}
