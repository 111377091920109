@use 'sass:map';
@use '@angular/material' as mat;

@import './variables';

.layout {
  $aside-left-width: 200px;
  $aside-left-width-wide: 240px;
  $aside-right-width: 256px;
  $aside-right-width-narrow: 256px;
  $drawer-width: 580px;
  $dialog-width: 480px;

  &__main {
    min-height: 100vh;
    min-width: 300px;
    position: relative;
    transition: $default-transition;

    &.has-aside-left {
      margin-left: $aside-left-width;

      &--wide {
        margin-left: $aside-left-width-wide;
      }
    }

    &.has-aside-right {
      margin-right: $aside-right-width;

      &--narrow {
        height: 100px;
        margin-right: $aside-right-width-narrow;
        overflow-y: auto;
      }
    }

    /**
     *
     * Main structure
     *
     */

    &__wrap {
      // padding: $main-vertical-padding $main-horizontal-padding;
      padding: 28px 32px 32px;

      &--boxed {
        margin: 0 auto;
        max-width: 1040px;
      }
    }

    &__box {
      max-width: 960px;
    }

    &__head {
    }

    &__body {
      flex-grow: 1;
      padding: ($main-vertical-padding * 0.5) 0;
    }

    &__part {
      padding: ($main-vertical-padding * 0.5) 0;
    }

    &__foot {
    }

    &__tab {
      padding: ($main-vertical-padding * 0.5) 0;
    }

    &__scenario {
      margin: ($main-vertical-padding * 0.5) 0;
    }
  }

  &__aside {
    transition: $default-transition;
    z-index: 2;

    &--left {
      background: #e7f0f2;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: fixed;
      top: 0;
      width: $aside-left-width;

      &--wide {
        width: $aside-left-width-wide;
      }
    }

    &--right {
      background: map-get(map-get($ezp-theme, background), background);
      border-left: 1px solid $primary-border-color;
      height: 100%;
      position: fixed;
      right: 0;
      top: 0;
      width: $aside-right-width;

      &--narrow {
        width: $aside-right-width-narrow;
      }
    }

    &__head {
      padding: 0 $main-horizontal-padding ($main-vertical-padding * 0.5);

      &--split {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }

    &__tab {
      padding: ($main-vertical-padding * 0.5) $main-horizontal-padding;
    }

    &__part {
      padding: ($main-vertical-padding * 0.5) 0;

      &--separate {
        border-top: 1px solid $primary-border-color;
        margin: ($main-vertical-padding * 0.5) 0 0;
        padding: $main-vertical-padding 0 ($main-vertical-padding * 0.5);
      }

      .mat-form-field {
        &:last-child {
          margin-bottom: -1.25em; // FIXME
        }
      }
    }

    &__actions {
      display: flex;

      .efa-button + .efa-button {
        margin-left: 8px;
      }
    }

    &__property {
      display: flex;
      justify-content: space-between;
      margin: 8px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__drawer {
    background: transparentize($primary-font-color, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;

    &__body {
      background: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;

      position: absolute;
      right: 0;
      top: 0;
      width: $drawer-width;
    }

    &__head {
      box-shadow: 0 1px 0 0 $primary-border-color;
      flex-shrink: 0;
      margin: -4px 0 0;
      padding: $main-vertical-padding $main-horizontal-padding ($main-vertical-padding * 0.5);

      &--split {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }

    &__content {
      flex-grow: 1;
      overflow: auto;
      padding: ($main-vertical-padding * 0.5) $main-horizontal-padding;
    }

    &__foot {
      box-shadow: 0 -1px 0 0 $primary-border-color;
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      padding: $main-vertical-padding $main-horizontal-padding;
      position: relative;
      z-index: 1;

      .efa-button {
        width: calc(50% - #{$main-vertical-padding * 0.25});
      }
    }

    &__part {
      padding: ($main-vertical-padding * 0.5) 0;
    }

    &__close {
      &:hover {
        .mat-icon {
          color: $icon-color-high-emphasis;
        }
      }
    }
  }

  &__dialog {
    background: transparentize($primary-font-color, 0.5);
    height: 100%;
    left: 0;
    padding-top: 30px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;

    &__body {
      background: white;
      display: flex;
      flex-direction: column;
      height: auto;

      margin: auto;
      overflow: hidden;
      width: $dialog-width;
    }

    &__head {
      box-shadow: 0 1px 0 0 $primary-border-color;
      flex-shrink: 0;
      margin: -4px 0 0;
      padding: $main-vertical-padding $main-horizontal-padding ($main-vertical-padding * 0.5);

      &--split {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }

    &__content {
      flex-grow: 1;
      overflow: auto;
      padding: ($main-vertical-padding * 0.5) $main-horizontal-padding;
    }

    &__foot {
      box-shadow: 0 -1px 0 0 $primary-border-color;
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      padding: $main-vertical-padding $main-horizontal-padding;
      position: relative;
      z-index: 1;

      .efa-button {
        width: calc(50% - #{$main-vertical-padding * 0.25});
      }
    }

    &__part {
      padding: ($main-vertical-padding * 0.5) 0;
    }

    &__close {
      &:hover {
        .mat-icon {
          color: $icon-color-high-emphasis;
        }
      }
    }
  }
}

// -------------------------------------------
// Media queries
// -------------------------------------------

@media (max-width: $breakpoint-medium-max-width) {
  .layout {
    $aside-left-width: 160px;
    $aside-right-width: 256px;

    &__main {
      &.has-aside-left {
        margin-left: $aside-left-width;
      }
    }

    &__aside {
      &--left {
        width: $aside-left-width;
      }
    }
  }
}

@media (max-width: $breakpoint-small-max-width) {
  .layout {
    $aside-left-width: 70px;
    $aside-right-width: 256px;
    $aside-right-width-narrow: 256px;
    $drawer-width: 360px;
    $dialog-width: 360px;

    &__main {
      &.has-aside-left {
        margin-left: $aside-left-width;
      }

      &.has-aside-right {
        margin-right: $aside-left-width;

        &--narrow {
          margin-right: $aside-right-width-narrow;
        }
      }
    }

    &__aside {
      &--left {
        width: $aside-left-width;
      }

      &--right {
        &--narrow {
          width: 150px;
          @media (min-width: map-get($ezp-breakpoints, 'small')) {
            width: $aside-right-width-narrow;
          }
        }
      }
    }

    &__drawer {
      &__body {
        width: $drawer-width;
      }
    }

    &__dialog {
      &__body {
        width: $dialog-width;
      }
    }
  }
}
