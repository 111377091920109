@use '@angular/material' as mat;
.ezp-list {
  &__icon {
    color: map-get(map-get($ezp-theme, foreground), icon);

    &--okay {
      color: $ezp-color-teal;
    }

    &--warn {
      color: $ezp-color-yellow;
    }

    &--danger {
      color: $ezp-color-red;
    }
  }

  &__meta {
    color: map-get(map-get($ezp-theme, foreground), secondary-text);
  }

  &__item {
    display: flex;
    justify-content: space-between;

    &__right-content {
      color: #a9a9a9;
      font-size: 12px;
    }
  }

  &__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__left-content{
      display: flex;
      align-items: center;
      gap: 15px;
    }

    &__light-content{
      color: #a9a9a9;
      font-size: 12px; 
    }

    &__select-content{
      border: 1px solid #ccc; 
      border-radius: 4px; 
      padding: 8px;
      min-width: 250px;
      max-width: 250px;
    }
  
  }

  &__fetching {
    align-items: center;
    display: flex;
    height: 48px;
    padding: 0 16px 0 0;

    &__spinner {
      &,
      > svg {
        height: 24px !important;
        width: 24px !important;
      }

      &--selection {
        margin: 0 12px;
      }

      &--icon {
        margin: 0 20px;
      }
    }

    &__title {
      color: mat.get-color-from-palette(map-get($ezp-theme, primary));

      @include mat.typography-level($ezp-typography, subheading-2);
    }
  }
}

/**
 *
 * Layout overrides
 *
 */

.mat-list-base {
  &.ezp-list--filterable {
    margin-top: -8px;
  }

  .mat-list-item {
    .mat-list-text > .ezp-list__item {
      &--inset {
        padding-left: 48px;
      }
    }
  }
}

.mat-list-item-disabled {
  &.ezp-list--disabled {
    background-color: white;
  }
}
