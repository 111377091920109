.ezp-note {
  background: map-get(map-get($ezp-theme, background), background);
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px map-get(map-get($ezp-theme, foreground), divider);
  display: grid;
  grid-gap: 0 16px;
  grid-template: auto / 24px 1fr;
  padding: 20px 24px 12px;

  &__icon {
    color: map-get(map-get($ezp-theme, foreground), icon);
    margin: 2px 0 0;
  }
}
