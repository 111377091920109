// ==========================================================================
// Modules
// ==========================================================================

@use '@angular/material' as mat;

// ==========================================================================
// Rules
// ==========================================================================

.ezp-button {
  &--icon {
    .mat-button-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &--leading {
      .mat-icon {
        margin: 0 4px 0 -8px;
      }
    }

    &--trailing {
      .mat-icon {
        margin: 0 -8px 0 4px;
      }
    }
  }

  &--custom {
    align-items: center;
    appearance: none;
    background: var(--ezp-button-custom-background, transparent);
    border: 0;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
    color: var(--ezp-button-custom-color, var(--ezp-brand-primary-solid));
    cursor: pointer;
    display: flex;
    gap: 8px;
    height: 36px;
    justify-content: center;
    margin: 0;
    min-width: var(--ezp-button-custom-min-width, auto);
    padding: 0 16px;
    position: relative;
    text-align: center;
    z-index: 0;

    &--translucent {
      --ezp-button-custom-background: var(--ezp-white-20);
      --ezp-button-custom-color: var(--ezp-white-100);

      &:hover {
        --ezp-button-custom-background: var(--ezp-white-30);
      }

      &:active {
        --ezp-button-custom-background: var(--ezp-white-20);
      }
    }

    &--invert {
      --ezp-button-custom-background: var(--ezp-white-100);
      --ezp-button-custom-color: var(--ezp-brand-primary-solid);

      &:hover {
        --ezp-button-custom-background: var(--ezp-white-80);
      }

      &:active {
        --ezp-button-custom-background: var(--ezp-white-70);
      }
    }

    mat-icon {
      margin: 0 0 0 -4px;
    }

    @include mat.typography-level($ezp-typography, 'button');
  }
}
