@use '@angular/material' as mat;
.ezp-form-field {
  $underline: mat.get-color-from-palette(map-get($ezp-theme, foreground), divider, 0.38);

  &--full {
    width: 100%;
  }

  &--no-helper {
    margin-bottom: -1.34375em;
  }

  &--sidebar {
    margin-bottom: -1.34375em;

    .mat-form-field-wrapper {
      margin: 0 !important;
    }
  }

  &--grid {
    .mat-form-field-wrapper {
      margin: 0 !important;
    }

    &--no-helper {
      .mat-form-field-wrapper {
        padding: 0 !important;
      }
    }
  }

  &--densed {
    &.mat-form-field-appearance-fill {
      .mat-form-field-flex {
        padding-top: 0;
      }

      .mat-form-field-infix {
        border-top: 0;
        padding-bottom: 9px;
        padding-top: 12px;
      }

      .mat-form-field-prefix {
        .mat-icon {
          top: 5px;
        }
      }
    }
  }

  &.mat-form-field-appearance-fill {
    .mat-form-field-underline {
      &::before {
        background: $underline;
      }
    }

    &.mat-form-field-disabled {
      .mat-form-field-flex {
        background: mat.get-color-from-palette(map-get($ezp-theme, foreground), base, 0.04);
      }

      .mat-form-field-underline {
        &::before {
          background: $underline;
        }
      }
    }
  }

  .mat-input-element {
    &:disabled {
      -webkit-text-fill-color: mat.get-color-from-palette(
        map-get($ezp-theme, foreground),
        disabled-text
      );
    }
  }

  .mat-form-field-prefix {
    .mat-icon {
      color: map-get(map-get($ezp-theme, foreground), icon);
      margin: 0 12px 0 0;
      position: relative;
      top: -2px;
    }
  }

  .mat-hint {
    letter-spacing: 0em;
  }
}
