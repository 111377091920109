@import './variables';

.efa-paginator {
  margin: $main-vertical-padding 0 0;

  .mat-paginator-icon {
    display: inline;
    height: 28px;
    width: 28px;
  }

  .mat-form-field {
    width: 56px !important;
  }
}
