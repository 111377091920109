.ezp-spinner {
  &--table {
    margin: 24px auto;
  }
  @each $level in (('small', 16px), ('medium', 24px), ('large', 32px)) {
    &--#{nth($level, 1)} {
      &,
      > svg {
        height: nth($level, 2) !important;
        width: nth($level, 2) !important;
      }
    }
  }
}
