@use '@angular/material' as mat;
.ezp-code {
  background: map-get(map-get($ezp-theme, background), background);
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px map-get(map-get($ezp-theme, foreground), divider);
  display: block;
  font-size: mat.font-size($ezp-typography, body-1);
  line-height: mat.line-height($ezp-typography, body-1);
  padding: 8px 12px;
}
