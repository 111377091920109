@import './variables';

.efa-text-field {
  $_prefix-size: 24px;
  $_prefix-offset: 8px;

  font-size: 14px;
  width: 100%;

  .mat-icon {
    font-size: $_prefix-size;
    line-height: $_prefix-size;
  }

  .mat-form-field {
    &-prefix {
      align-self: flex-end;
      font-size: $_prefix-size;
      height: $_prefix-size;
      line-height: $_prefix-size;
      margin: 0 $_prefix-offset 2px 0;
      width: $_prefix-size;
    }

    &-underline {
      background: $primary-border-color;
    }
  }

  &--prefixed {
    .mat-form-field-subscript-wrapper {
      padding: 0 0 0 ($_prefix-size + $_prefix-offset);
    }
  }

  &.mat-focused {
    .efa-icon {
      color: $primary-color;
    }
  }
}
