@use '@angular/material' as mat;
.ezp-menu {
  &__item {
    &--destructive {
      color: mat.get-color-from-palette(map-get($ezp-theme, accent));
    }
  }

  &__group-title {
    color: map-get(map-get($ezp-theme, foreground), secondary-text);
    display: block;
    margin: 8px 0 0;
    padding: 0 16px;

    &:not(:first-child) {
      margin: 16px 0 0;
    }
  }
}
