.efa-note {
  border-radius: 4px;
  padding: 16px;

  &--gray {
    background: transparentize($primary-font-color, 0.95);
    box-shadow: inset 0 0 0 1px transparentize($primary-font-color, 0.9);
  }
  &--centered {
    margin: auto;
    text-align: center;
    width: fit-content;
  }
}
