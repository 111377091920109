.center-content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 2rem;
  width: 100%;
}

.frow {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.full-row {
  justify-content: space-between;
  @extend .frow;
}

@mixin center-element($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}
