@use '@angular/material' as mat;
.ezp-drawer {
  background: map-get(map-get($ezp-theme, background), background);
  width: 256px;

  &__logo {
    fill: var(--ezp-brand-primary-solid);
    margin: 32px 0 0 16px;
  }

  &__nav {
    padding: 0 8px;

    &__item {
      border-radius: 4px;
      height: 40px !important;

      .mat-list-icon {
        color: map-get(map-get($ezp-theme, foreground), icon);
        padding: 0 !important;
      }

      .mat-ripple {
        border-radius: 4px;
      }

      &--active {
        &::after {
          background: mat.get-color-from-palette(map-get($ezp-theme, primary));
          border-radius: 4px;
          bottom: 0;
          content: '';
          display: block;
          left: 0;
          opacity: 0.12;
          position: absolute;
          right: 0;
          top: 0;
          z-index: -1;
        }

        .mat-line,
        .mat-icon {
          color: mat.get-color-from-palette(map-get($ezp-theme, primary));
        }
      }

      & + & {
        margin: 8px 0 0;
      }
    }

    .mat-divider {
      margin: 0 -8px 8px;
    }
  }

  &__foot {
    padding: 24px;
    flex-grow: 1;
    display: flex;
  }

  &__meta {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    align-self: flex-end;
    flex-grow: 1;
  }

  &__link {
    color: map-get(map-get($ezp-theme, foreground), disabled-button);
    cursor: pointer;
    height: 24px;

    &:hover {
      color: map-get(map-get($ezp-theme, foreground), disabled);
    }
  }

  &__colophon {
    align-items: center;
    color: map-get(map-get($ezp-theme, foreground), secondary-text);
    display: flex;
    margin: 8px 0 0;

    .mat-icon {
      margin: 0 2px;
    }

    @include mat.typography-level($ezp-typography, body-1);
  }

  .mat-drawer-inner-container {
    display: flex;
    flex-direction: column;
  }

  .mat-list-item {
    .mat-list-text {
      .mat-line {
        @include mat.typography-level($ezp-typography, body-2);
      }
    }
  }

  &__language-switch {
    line-height: 32px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 5;

    &--list-open {
      --ezp-drawer-language-switch-list-display: block;
    }

    &__toggle {
      background: var(--ezp-drawer-language-switch-toggle-background, transparent);
      border-radius: 4px;
      box-shadow: inset 0 0 0 1px var(--ezp-core-outline);
      color: var(--ezp-core-foreground-secondary);
      cursor: pointer;
      font-weight: 600;
      height: 32px;
      width: 36px;

      &:hover {
        --ezp-drawer-language-switch-toggle-background: var(--ezp-core-shade-primary);
      }
    }

    &__list {
      bottom: 36px;
      display: var(--ezp-drawer-language-switch-list-display, none);
      left: 0;
      position: absolute;

      &::after {
        border-color: var(--ezp-black-60) transparent transparent transparent;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        bottom: -4px;
        content: '';
        height: 0;
        left: 14px;
        position: absolute;
        width: 0;
      }

      &__item {
        background: var(--ezp-drawer-language-switch-list-item-background, var(--ezp-black-60));
        border-radius: 4px;
        color: var(--ezp-white-100);
        cursor: pointer;
        font-weight: 600;
        height: 32px;
        line-height: 32px;
        width: 36px;

        &:hover {
          --ezp-drawer-language-switch-list-item-background: var(--ezp-black-70);
        }
      }
    }
  }
}
