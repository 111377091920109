.efa-table {
  &__cell {
    &--select {
      width: 56px;
    }
  }

  th,
  td {
    border: 0 !important;
    font-size: 12px !important;
  }

  th {
    box-shadow: inset 0 -2px 0 0 $primary-border-color;
  }

  td {
    box-shadow: inset 0 -1px 0 0 $primary-border-color;
  }

  tbody {
    tr {
      &:hover {
        background-color: transparentize($primary-font-color, 0.95);
      }
    }
  }
}
